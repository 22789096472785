<template>
  <div class="">
    <b-card class="">
      <div class="mb-1 d-flex justify-content-between">
        <strong>
          Real Estate Licensees
        </strong>
      </div>
      <b-table
        small
        :fields="fields"
        :items="agents"
        responsive="sm"
        selectable
        select-mode="single"
        @row-clicked="handleRowClick"
      >
        <template #cell(action)="data">
          <hwa-button
            :icon="data.item.active?'UserXIcon':'UserCheckIcon'"
            :icon-only="true"
            :variant="data.item.active?'flat-danger':'flat-success'"
            @onClick="updateAgent(data.item)"
          />
        </template>
        <template #cell(can_login)="data">
          <b-badge :variant="data.item.can_login?'light-success':'light-danger'">
            {{ data.item.can_login?"Enabled":"Disabled" }}
          </b-badge>
        </template>
        <template #cell(status)="data">
          <b-badge variant="light-success">
            {{ data.item.status==='active'?"Active":"Inactive" }}
          </b-badge>
        </template>
        <template #cell(licensee_type)="data">
          <div
            class="px-1"
          >
            <b-badge variant="light-success">
              {{data.item.licensee_type }}
            </b-badge>
          </div>
        </template>
      </b-table>
    </b-card>
    <validation-observer
      ref="agentForm"
      v-slot="{invalid}"
    >
      <hwa-modal
        :title="`Real Estate Licensee`"
        :show="agentModalOpened"
        size="md"
        hide-footer
        :is_data_change="is_agent_change"
        @onClose="closeAgentModal()"
      >
        <div class="row">
          <div class="col-md-6"> <hwa-input
            v-model="agent.first_name"
            name="name"
            label="First Name"
            placeholder="Enter first name"
            rules="required"
          /></div>
          <div class="col-md-6">   <hwa-input
            v-model="agent.last_name"
            name="name"
            label="Last Name"
            placeholder="Enter last name"
            rules="required"
          />
          </div>
          <div class="col-md-6">
            <hwa-input
              v-model="agent.email"
              name="name"
              label="Email"
              placeholder="Enter email"
              rules="required"
              type="email"
            />

          </div>
          <div class="col-md-6">
            <hwa-input
              v-model="agent.phone"
              name="name"
              label="Phone Number"
              placeholder="Enter phone number"
            />
          </div>
        </div>

        <!-- Broker Details starts here -->
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              label="License Type"
              label-for="register-license_type"
            >
              <validation-provider
                #default="{ errors }"
                name="License Type"
                rules="required"
              >
                <b-form-select
                  id="register-license_type"
                  v-model="agent.licensee_type"
                  name="register-license_type"
                  :state="errors.length > 0 ? false:null"
                  :options="options"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <div class="col-md-6">
            <b-form-group
              label="Brokerage(Company)"
              label-for="register-brokerage"
            >
              <validation-provider
                #default="{ errors }"
                name="Brokerage"
                rules="required"
              >
                <b-form-input
                  id="register-brokerage"
                  v-model="agent.brokerage_firm"
                  name="register-brokerage"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Brokerage name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <div class="col-md-6">
            <b-form-group
              label="Supervising Broker"
              label-for="register-broker"
            >
              <validation-provider
                #default="{ errors }"
                name="Broker"
                rules="required"
              >
                <b-form-input
                  id="register-broker"
                  v-model="agent.broker_name"
                  name="register-broker"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Broker name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <div class="col-md-6">
            <b-form-group
              label="Supervising Broker Phone Number"
              label-for="register-broker_phone_number"
            >
              <validation-provider
                #default="{ errors }"
                name="Supervising Broker Phone Number"
                rules="required"
              >
                <b-form-input
                  id="register-broker_phone_number"
                  v-model="agent.broker_phone"
                  name="register-broker_phone_number"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Supervising Broker phone number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
        <!-- Broker Details ends here -->

        <div class="row mt-3">
          <!-- <div class="col-md-3">
            <b-form-group label="Status">
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                switch
                :checked="agent.status === 'active'? true : false"
                @change="setActiveUser(agent)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </div> -->
          <div class="col-md-6">
            <b-form-group label="Login Access">
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                switch
                :checked="Boolean(agent.can_login) === true ? true : false"
                @change="setLoginAccess(agent)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="UserCheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="UserXIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>

        <div
          v-if="$can('update-real-estate-licensees','hwa')"
          class="d-flex mt-3 justify-content-between"
        >
          <hwa-button
            variant="secondary"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            :class="is_agent_change ? 'active-secondary' : ''"
            @onClick="closeAgentModal()"
          />
          <hwa-button
            variant="success"
            :class="is_agent_change === true ? 'active-success' : ''"
            :label="updateAgentMode?'Save':'Save'"
            :loading="agentLoading"
            loading-text="Loading..."
            :disable="invalid || is_agent_change === false ? true : false"
            @onClick="updateAgent()"
          />
        </div>
      </hwa-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BTable, BCard, BBadge, BFormGroup, BFormInput, BInputGroup, BFormSelect, BFormRadioGroup, BRow, BFormCheckbox,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import showToast from '@/mixins/showToast'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaModal from '@/layouts/components/HwaModal.vue'
import confirm from '@/mixins/confirm'

export default {
  components: {
    HwaModal,
    HwaInput,
    HwaButton,
    BFormSelect,
    BFormCheckbox,
    BTable,
    BCard,
    BBadge,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormRadioGroup,
    ValidationProvider,
    BRow,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      fields: [{key:'full_name',label:"Full Name",sortable:true}, 'email', 'phone', {key:'can_login',label:"Login Access",sortable:true}, {key:'licensee_type',label:"Role",sortable:true}],
      agents: [],
      agent_name: '',
      agent_id: null,
      agentLoading: false,
      updateAgentMode: false,
      agentModalOpened: false,
      selectedAgent: {},
      agent: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        can_login: true,
        status: 'active',
        licensee_type: null,
        brokerage_firm: '',
        broker_name: '',
        broker_phone: '',
      },
      options: [
        { value: null, text: 'Please select license Type' },
        { value: 'Real Estate Broker', text: 'Real Estate Broker' },
        { value: 'Real Estate Salesperson', text: 'Real Estate Salesperson' },
        { value: 'Real Estate Associate Broker', text: 'Real Estate Associate Broker' },
      ],
      roles: [],
      teams: [],
      is_agent_change: false,
      check_agent_change: false,
    }
  },
  watch: {
    agent: {
      handler() {
        this.is_agent_change = true
      },
      deep: true,
    },
  },
  mounted() {
    this.getAgents()
  },
  methods: {
    openAgentModal() {
      this.updateAgentMode = false
      this.agentModalOpened = true
    },
    closeAgentModal() {
      if (this.is_agent_change) {
        this.$swal({
          title: 'Do you want to save changes?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save changes!',
          cancelButtonText: 'Discard Changes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.agentModalOpened = false
            this.is_agent_change = false
            this.check_agent_change = false
            this.updateAgent()
          } else if (result.dismiss === 'cancel') {
            this.agentModalOpened = false
            this.is_agent_change = false
            this.check_agent_change = false
            // this.closeMortgageModal()
          }
        })
      } else {
        this.agentModalOpened = false
        this.is_agent_change = false
        this.check_agent_change = false
      }
    },
    setActiveUser(data) {
      if (data.status === 'active') {
        this.agent.status = 'inactive'
      } else {
        this.agent.status = 'active'
      }
    },
    setLoginAccess(data) {
      if (Boolean(data.can_login) === true) {
        this.agent.can_login = false
      } else {
        this.agent.can_login = true
      }
    },
    updateAgent() {
      this.agentLoading = true
      this.$http.put('/api/update-user-account', this.agent)
        .then(res => {
          this.agentLoading = false
          this.agents = this.agents.map(c => {
            if (c.id === res.data.id) {
              return c
            }
            return c
          })
          this.showSuccess('Updated succesfully')
          this.agentModalOpened = false
          this.is_agent_change = false
          this.getAgents()
        })
        .catch(err => {
          this.agentLoading = false
          this.showError('Failed to update agents')
        })
    },
    handleRowClick(row) {
      if (row) {
        this.agent = { ...row }
        this.updateAgentMode = true
        this.agentModalOpened = true

        const dom = this
        setTimeout(() => {
          dom.is_agent_change = false
        }, 100)
        // this.is_agent_change = false
      }
    },
    getAgents() {
      this.$http.get('/api/agents')
        .then(res => {
          this.agents = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve agents')
        })
    },
  },
}
</script>
