var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-card',{},[_c('div',{staticClass:"mb-1 d-flex justify-content-between"},[_c('strong',[_vm._v(" Real Estate Licensees ")])]),_c('b-table',{attrs:{"small":"","fields":_vm.fields,"items":_vm.agents,"responsive":"sm","selectable":"","select-mode":"single"},on:{"row-clicked":_vm.handleRowClick},scopedSlots:_vm._u([{key:"cell(action)",fn:function(data){return [_c('hwa-button',{attrs:{"icon":data.item.active?'UserXIcon':'UserCheckIcon',"icon-only":true,"variant":data.item.active?'flat-danger':'flat-success'},on:{"onClick":function($event){return _vm.updateAgent(data.item)}}})]}},{key:"cell(can_login)",fn:function(data){return [_c('b-badge',{attrs:{"variant":data.item.can_login?'light-success':'light-danger'}},[_vm._v(" "+_vm._s(data.item.can_login?"Enabled":"Disabled")+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{attrs:{"variant":"light-success"}},[_vm._v(" "+_vm._s(data.item.status==='active'?"Active":"Inactive")+" ")])]}},{key:"cell(licensee_type)",fn:function(data){return [_c('div',{staticClass:"px-1"},[_c('b-badge',{attrs:{"variant":"light-success"}},[_vm._v(" "+_vm._s(data.item.licensee_type)+" ")])],1)]}}])})],1),_c('validation-observer',{ref:"agentForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('hwa-modal',{attrs:{"title":"Real Estate Licensee","show":_vm.agentModalOpened,"size":"md","hide-footer":"","is_data_change":_vm.is_agent_change},on:{"onClose":function($event){return _vm.closeAgentModal()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('hwa-input',{attrs:{"name":"name","label":"First Name","placeholder":"Enter first name","rules":"required"},model:{value:(_vm.agent.first_name),callback:function ($$v) {_vm.$set(_vm.agent, "first_name", $$v)},expression:"agent.first_name"}})],1),_c('div',{staticClass:"col-md-6"},[_c('hwa-input',{attrs:{"name":"name","label":"Last Name","placeholder":"Enter last name","rules":"required"},model:{value:(_vm.agent.last_name),callback:function ($$v) {_vm.$set(_vm.agent, "last_name", $$v)},expression:"agent.last_name"}})],1),_c('div',{staticClass:"col-md-6"},[_c('hwa-input',{attrs:{"name":"name","label":"Email","placeholder":"Enter email","rules":"required","type":"email"},model:{value:(_vm.agent.email),callback:function ($$v) {_vm.$set(_vm.agent, "email", $$v)},expression:"agent.email"}})],1),_c('div',{staticClass:"col-md-6"},[_c('hwa-input',{attrs:{"name":"name","label":"Phone Number","placeholder":"Enter phone number"},model:{value:(_vm.agent.phone),callback:function ($$v) {_vm.$set(_vm.agent, "phone", $$v)},expression:"agent.phone"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"License Type","label-for":"register-license_type"}},[_c('validation-provider',{attrs:{"name":"License Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"register-license_type","name":"register-license_type","state":errors.length > 0 ? false:null,"options":_vm.options},model:{value:(_vm.agent.licensee_type),callback:function ($$v) {_vm.$set(_vm.agent, "licensee_type", $$v)},expression:"agent.licensee_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Brokerage(Company)","label-for":"register-brokerage"}},[_c('validation-provider',{attrs:{"name":"Brokerage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-brokerage","name":"register-brokerage","state":errors.length > 0 ? false:null,"placeholder":"Brokerage name"},model:{value:(_vm.agent.brokerage_firm),callback:function ($$v) {_vm.$set(_vm.agent, "brokerage_firm", $$v)},expression:"agent.brokerage_firm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Supervising Broker","label-for":"register-broker"}},[_c('validation-provider',{attrs:{"name":"Broker","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-broker","name":"register-broker","state":errors.length > 0 ? false:null,"placeholder":"Broker name"},model:{value:(_vm.agent.broker_name),callback:function ($$v) {_vm.$set(_vm.agent, "broker_name", $$v)},expression:"agent.broker_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Supervising Broker Phone Number","label-for":"register-broker_phone_number"}},[_c('validation-provider',{attrs:{"name":"Supervising Broker Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-broker_phone_number","name":"register-broker_phone_number","state":errors.length > 0 ? false:null,"placeholder":"Supervising Broker phone number"},model:{value:(_vm.agent.broker_phone),callback:function ($$v) {_vm.$set(_vm.agent, "broker_phone", $$v)},expression:"agent.broker_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Login Access"}},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"name":"check-button","switch":"","checked":Boolean(_vm.agent.can_login) === true ? true : false},on:{"change":function($event){return _vm.setLoginAccess(_vm.agent)}}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"UserCheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"UserXIcon"}})],1)])],1)],1)]),(_vm.$can('update-real-estate-licensees','hwa'))?_c('div',{staticClass:"d-flex mt-3 justify-content-between"},[_c('hwa-button',{staticClass:"mr-1",class:_vm.is_agent_change ? 'active-secondary' : '',attrs:{"variant":"secondary","icon":"XIcon","label":"Cancel"},on:{"onClick":function($event){return _vm.closeAgentModal()}}}),_c('hwa-button',{class:_vm.is_agent_change === true ? 'active-success' : '',attrs:{"variant":"success","label":_vm.updateAgentMode?'Save':'Save',"loading":_vm.agentLoading,"loading-text":"Loading...","disable":invalid || _vm.is_agent_change === false ? true : false},on:{"onClick":function($event){return _vm.updateAgent()}}})],1):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }